import React from 'react';
import { useShoppingContext } from '../../context/ShoppingContext';

const CheckoutForm = (props) => {
  const [shoppingContext, setShoppingContext] = useShoppingContext();

  const formhandler = (e) => {
    const id = e.target.id;

    setShoppingContext({
      type: 'formUpdate',
      value: { id: id, value: e.target.value },
    });
  };

  return (
    <form>
      <div className="mb-2">
        <label className="checkout">
          Vorname / Nachname<sup className="text-danger">*</sup>:
        </label>
        <div className="row">
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="prename"
              id="prename"
              placeholder="Vorname"
              value={shoppingContext.contact.prename}
              onChange={(e) => formhandler(e)}
            />
          </div>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="name"
              id="name"
              placeholder="Nachname"
              value={shoppingContext.contact.name}
              onChange={(e) => formhandler(e)}
            />
          </div>
        </div>
      </div>
      <div className="mb-2">
        <label className="checkout">
          E-Mail<sup className="text-danger">*</sup>:
        </label>
        <input
          className="form-control"
          type="text"
          name="email"
          id="email"
          placeholder="E-Mail"
          value={shoppingContext.contact.email}
          onChange={(e) => formhandler(e)}
        />
      </div>

      <div className="mb-2">
        <label className="checkout">
          Adresse<sup className="text-danger">*</sup>:
        </label>
        <input
          className="form-control"
          type="text"
          name="address"
          id="address"
          placeholder="Adresse"
          value={shoppingContext.contact.address}
          onChange={(e) => formhandler(e)}
        />
      </div>
      <div className="mb-2">
        <label className="checkout">
          PLZ / Ort<sup className="text-danger">*</sup>:
        </label>
        <div className="row">
          <div className="col">
            <input
              className="form-control"
              type="number"
              name="plz"
              id="plz"
              pattern="[0-9]{4}"
              placeholder="PLZ"
              value={shoppingContext.contact.plz}
              onChange={(e) => formhandler(e)}
            />
          </div>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="place"
              id="place"
              placeholder="Ort"
              value={shoppingContext.contact.place}
              onChange={(e) => formhandler(e)}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
