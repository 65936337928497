export const card1 = require('./Postkarte_Boedeliblick_A6.jpg');
export const card2 = require('./Postkarte_Brienzerseeblick_A6.jpg');
export const card3 = require('./Postkarte_Europaallee_A6.jpg');
export const card4 = require('./Postkarte_Roselaui_A6.jpg');
export const card5 = require('./Postkarte_Justistal_A6.jpg');
export const card6 = require('./Postkarte_Eiger_Moench_Jungfrau_A6.jpg');
export const card7 = require('./Postkarte_Lombachalp_A6.jpg');
export const card8 = require('./Postkarte_Eigernordwand_A6.jpg');

export const getCard = (name) => {
  switch (name) {
    case 'Bödeliblick':
      return card1;
    case 'Brienzerseeblick':
      return card2;
    case 'Europaallee':
      return card3;
    case 'Roselaui':
      return card4;
    case 'Justistal':
      return card5;
    case 'Eiger, Mönch & Jungfrau':
      return card6;
    case 'Lombachalp':
      return card7;
    case 'Eiger':
      return card8;
    default:
      return card4;
  }
};
