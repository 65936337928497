import axios from 'axios';

const api = async (route, data) => {
  switch (route) {
    case 'order':
      let r = await axios.post(
        `https://api.cards.waremama.ch/?method=${route}`,
        data
      );
      return r;

    default:
      break;
  }
};

export default api;
