import React from 'react';
import PageWrapper from '../pageWrapper/PageWrapper';

const Contact = (props) => {
  return (
    <PageWrapper {...props}>
      <div className="col-12">
        <h1 className="text-center">Impressum?</h1>
        <p className="text-center">Hä, braucht man so was überhaupt!</p>
      </div>
    </PageWrapper>
  );
};

export default Contact;
