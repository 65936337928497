import React from 'react';
import { useShoppingContext } from '../../context/ShoppingContext';

const CheckoutSummary = (props) => {
  const [shoppingState, dispatch] = useShoppingContext();

  let total = 0;
  return (
    <table className="table table-fixed">
      <thead className="thead">
        <tr>
          <td>Artikel</td>
          <td>Preis</td>
          <td>Anzahl</td>
        </tr>
      </thead>
      <tbody>
        {shoppingState.basket.map((item) => {
          total += item.price * item.count;
          return (
            <tr>
              <td>{item ? item.name : ''}</td>
              <td>{item ? 'CHF ' + item.price.toFixed(2) + '.-' : ''}</td>
              <td>{item ? item.count : ''}</td>
            </tr>
          );
        })}

        <tr>
          <td className="font-weight-bold text-right" colSpan="3">
            Total: CHF {total.toFixed(2)} .-{' '}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CheckoutSummary;
