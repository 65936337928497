import React from 'react';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SocialMedia.css';

const SocialMedia = (props) => {
  return props.show ? (
    <>
      <div className="icon-bar">
        <a href="http://instagram.com/bovu1" class="instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </>
  ) : (
    <></>
  );
};

export default SocialMedia;
