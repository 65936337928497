import React, { createContext, useReducer, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

const ShoppingContext = createContext();
const reducer = (state, action) => {
  switch (action.type) {
    case 'addItem':
      if (state.basket.some((product) => product.name == action.item.name)) {
        return { ...state };
      } else {
        return {
          ...state,
          basket: [...state.basket, { ...action.item, count: 1 }],
        };
      }

    case 'incrementCount': {
      let item = state.basket.find((product) => {
        return product.name == action.item.name;
      });
      item.count += 1;
      return state;
      break;
    }

    case 'formUpdate': {
      state.contact[action.value.id] = action.value.value;
      return state;
      break;
    }
    case 'decrementCounter': {
      let item = state.basket.find((product) => {
        return product.name == action.item.name;
      });
      if (item) {
        if (item.count > 1) {
          item.count -= 1;
        } else if (item.count > 0) {
          const index = state.basket.indexOf(item);
          if (index > -1) {
            state.basket.splice(index, 1);
          }
        }
      }
      return state;
    }
    default:
      return;
  }
};

const ShoppingContextProvider = (props) => {
  const initialState = { basket: [], contact: {} };

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  return (
    <ShoppingContext.Provider value={[state, dispatch]}>
      {props.children}
    </ShoppingContext.Provider>
  );
};

const useShoppingContext = () => {
  return useContext(ShoppingContext);
};

export { ShoppingContextProvider, ShoppingContext, useShoppingContext };
