import React, { useState } from 'react';
import {
  faShoppingCart,
  faPlus,
  faMinus,
  faCashRegister,
  faSmile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'react-bootstrap';
import { useShoppingContext } from '../../context/ShoppingContext';

import './BuyNow.css';

const BuyNow = (props) => {
  const [showModal, setShwoModal] = useState(false);
  const [shoppingState, dispatch] = useShoppingContext();

  const item = shoppingState.basket.find(
    (item) => item.name == props.product.name
  );
  return (
    <>
      <div className="my-2">
        <button
          className="btn btn-primary"
          onClick={() => {
            dispatch({ type: 'addItem', item: props.product });
            setShwoModal(true);
          }}
        >
          Jetzt bestellen! <FontAwesomeIcon icon={faShoppingCart} />
        </button>
      </div>
      <Modal
        show={showModal}
        onHide={() => {
          setShwoModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Jetzt bestellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-fixed">
            <thead className="thead">
              <tr>
                <td>Artikel</td>
                <td>Preis</td>
                <td>Anzahl</td>
              </tr>
            </thead>
            <tbody>
              <td>{item ? item.name : props.product.name}</td>
              <td>
                {item
                  ? 'CHF ' + item.price.toFixed(2) + '.-'
                  : 'CHF ' + props.product.price.toFixed(2) + '.-'}
              </td>
              <td>{item ? item.count : 0}</td>
            </tbody>
          </table>
          <div className="d-flex flex-row justify-content-around">
            <div className="font-weight-bold">
              Total:{' '}
              {item
                ? 'CHF ' + (item.count * item.price).toFixed(2) + '.-'
                : 'CHF ' + (0).toFixed(2) + '.-'}
            </div>
            <div className="controls d-flex flex-row">
              <Button
                variant="warning"
                onClick={() => {
                  dispatch({ type: 'decrementCounter', item: props.product });
                }}
              >
                <FontAwesomeIcon icon={faMinus} color="white" />
              </Button>
              <div className="mx-4"></div>
              <Button
                variant="success"
                onClick={() => {
                  if (item) {
                    dispatch({ type: 'incrementCount', item: props.product });
                  } else {
                    dispatch({ type: 'addItem', item: props.product });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShwoModal(false);
            }}
          >
            Abbruch
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShwoModal(false);
            }}
          >
            weiter <FontAwesomeIcon icon={faShoppingCart} />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyNow;
