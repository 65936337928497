import React from 'react';
import PageWrapper from '../pageWrapper/PageWrapper';

const About = (props) => {
  return (
    <PageWrapper {...props}>
      <div className="col-12">
        <h1 className="text-center">Tell me about you Bobi!</h1>
        <p className="text-center">
          Sony Please sponsor me, I am a poor student!
        </p>
        <p className="text-center">PayPal Me!</p>
        <img className="img-fluid" src={'g'} alt="logo" />
      </div>
    </PageWrapper>
  );
};

export default About;
