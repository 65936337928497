import React from 'react';
import './Footer.css';

const Footer = (props) => {
  return (
    <footer className="footer bg-dark text-right">
      <div className="container-fluid">
        <span className="text-muted">© Juli 2020</span>
      </div>
    </footer>
  );
};

export default Footer;
