import React from 'react';
import ReactGA from 'react-ga';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

const PageWrapper = (props) => {
  ReactGA.initialize('UA-178051695-1');
  ReactGA.pageview(window.location.hash);
  return (
    <>
      <Header {...props} />
      <div id="main" className="container-fluid py-3">
        <div className="row">{props.children}</div>
      </div>

      <Footer />
    </>
  );
};

export default PageWrapper;
