import React from 'react';
import { useShoppingContext } from '../../context/ShoppingContext';

import api from '../../helpers/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';

import './Checkout.css';

const Checkout = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shoppingState, dispatch] = useShoppingContext();

  const verifyContactInformation = async (contact) => {
    if (
      contact.name &&
      contact.prename &&
      contact.address &&
      contact.plz &&
      contact.plz
    ) {
      let r = await api('order', shoppingState);
      console.log(r);
      alert('Vielen Dank für Ihre Bestellung');
      setShowModal(false);
    } else {
      setErrorMessage('TODO: More error handling!');
    }
  };

  if (shoppingState.basket.length) {
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Jetzt bestellen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center text-danger">{errorMessage}</div>
            <CheckoutForm />
            <hr />
            <div>
              <h5>Deine Bestellung:</h5>
            </div>
            <CheckoutSummary />
            <div>
              <h6>Zahlungskonditionen</h6>
              <p className="small">
                Die Zahlung erfolgt per Banküberweisung, du erhälst nach dem
                Bestellvorgang eine E-Mail mit den Instruktionen. Der Betrag ist
                zahlbar innerhalb 14 Tagen. Die Postkarten werden nach dem
                Zahlungseingang per A-Post verschickt.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Abbruch
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                await verifyContactInformation(shoppingState.contact);
                console.log(shoppingState);
              }}
            >
              bestellen <FontAwesomeIcon icon={faShoppingCart} />
            </Button>
          </Modal.Footer>
        </Modal>
        <div
          className="d-flex checkout-container"
          onClick={() => setShowModal(!showModal)}
        >
          <div className="d-flex m-auto checkout-btn">
            <div className="d-flex m-auto">
              <FontAwesomeIcon icon={faShoppingCart} size={'2x'} />
            </div>
            <span className="font-weight-bold basket-count">
              {shoppingState.basket.length}
            </span>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Checkout;
