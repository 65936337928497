import React, { useEffect, useState } from 'react';
import { ShoppingContextProvider } from '../../context/ShoppingContext';
import PageWrapper from '../pageWrapper/PageWrapper';
import SocialMedia from '../../elements/SocialMedia/SocialMedia';
import BuyNow from '../../elements/BuyNow/BuyNow';
import './Home.css';
import products from '../../static/products/products.json';
import { getCard } from '../../static/img/images';
import MyCarousel from '../../elements/Carousel/Carousel';
import Checkout from '../../elements/Checkout/Checkout';

const Home = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const updateMousePosition = (e) => {
    let cord = e.clientX;
    if (cord < 200) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);
    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return (
    <ShoppingContextProvider>
      <PageWrapper {...props}>
        <Checkout />
        <SocialMedia show={showSidebar} />
        <div className="col-12">
          <h2 className="text-center">Bobi's Postkarten!</h2>
          <h4 className="text-center">
            Postkarten aus der Region - für die ganze Welt!
          </h4>
          <div className="text-center mx-auto mb-3">
            Mach deinen Freunden eine Freude und sende ihnen Postkarten von
            deiner Heimat! So bringst du ein Stück Schweiz in die ganze Welt!
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <MyCarousel />
            </div>
          </div>
          <div className="row mt-lg-4 mt-md-0">
            {products
              .filter((product) => {
                return !product.vert;
              })
              .map((product) => {
                if (product.vert) {
                  return (
                    <div className="col-lg-4 mb-4 text-center">
                      <h4>{product.name}</h4>
                      <img
                        className="img-fluid"
                        src={getCard(product.name)}
                        alt="logo"
                      />
                      <BuyNow product={product} />
                    </div>
                  );
                } else {
                  return (
                    <div className="col-lg-4 mb-4 text-center">
                      <h4>{product.name}</h4>
                      <img
                        className="img-fluid"
                        src={getCard(product.name)}
                        alt="logo"
                      />
                      <BuyNow product={product} />
                    </div>
                  );
                }
              })}
          </div>
          <div className="row mt-lg-4 mt-md-0">
            {products
              .filter((product) => {
                return product.vert;
              })
              .map((product) => {
                if (product.vert) {
                  return (
                    <div className="col-lg-4 mb-4 text-center">
                      <h4>{product.name}</h4>
                      <img
                        className="img-fluid"
                        src={getCard(product.name)}
                        alt="logo"
                      />
                      <BuyNow product={product} />
                    </div>
                  );
                } else {
                  return (
                    <div className="col-lg-4 mb-4 text-center">
                      <h4>{product.name}</h4>
                      <img
                        className="img-fluid"
                        src={getCard(product.name)}
                        alt="logo"
                      />
                      <BuyNow product={product} />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </PageWrapper>
    </ShoppingContextProvider>
  );
};

export default Home;
