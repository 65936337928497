import React from 'react';
import { useState } from 'react';

const Header = (props) => {
  const {
    location: { pathname },
  } = props;

  const [collapsed, setCollapsed] = useState(true);

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <a className="navbar-brand" href="#">
          Bobi's Postkarten
        </a>
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="navbar-toggler"
          type="button"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={collapsed ? 'collapse navbar-collapse' : 'navbar-collapse'}
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className={pathname === '/' ? 'nav-link active' : 'nav-link'}
                href="/"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  pathname === '/about' ? 'nav-link active' : 'nav-link'
                }
                href="/#/about"
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  pathname === '/contact' ? 'nav-link active' : 'nav-link'
                }
                href="/#/contact"
              >
                Contact
              </a>
            </li>
            {/*
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dropdown link
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
</li>*/}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
