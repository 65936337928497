import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './Carousel.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
} from '../../static/img/images';

const MyCarousel = (props) => {
  return (
    <Carousel showArrows={true} labels={false} dynamicHeight={true}>
      <div>
        <img src={card1} />
      </div>
      <div>
        <img src={card3} />
      </div>
      <div>
        <img src={card4} />
      </div>
      <div>
        <img src={card6} />
      </div>
      <div>
        <img src={card7} />
      </div>
      <div>
        <img src={card2} />
      </div>
      <div>
        <img src={card5} />
      </div>
      <div>
        <img src={card8} />
      </div>
    </Carousel>
  );
};

export default MyCarousel;
